import {store} from '../../store';
import {mapActions} from 'vuex';

let recommendationMixins = {
  data: function() {
    return {
      courseFitData: {
        'recommended_courses': {'8': false, '6_7': false},
        'recommended_colleges': {'8': false, '6_7': false},
      },
      filteredRecommendations: false,
      filterCollegeName: {'8': false, '6_7': false},
      filterGrade: 'overall',
    };
  },
  beforeMount: function() {
    if (!store.state.selectedCollege['8']) {
      store.state.selectedCollege['8'] = store.state.favouriteCollege;
    }
    if (!store.state.selectedCollege['6_7']) {
      store.state.selectedCollege['6_7'] = store.state.favouriteCollege;
    }
    this.filterCollegeName['8'] = store.state.selectedCollege['8'];
    this.filterCollegeName['6_7'] = store.state.selectedCollege['6_7'];
    if (store.state.recommendations.courses !== undefined &&
        store.state.recommendations.courses) {
      if (store.state.recommendations.courses[this.level] !== undefined)
        this.courseFitData.recommended_courses[this.level] = store.state.recommendations.courses[this.level][this.filterCollegeName[this.level]];
    }
  },
  computed: {
    getFilterCollegeName: function() {
      return this.filterCollegeName[this.level];
    },
    getTopIrelandCourses: function() {
      return store.state.recommendations.top_5_ie[this.level];
    },
    getCourseFitData: function() {
      let courses = store.state.recommendations.courses[this.level];
      let keys = Object.keys(courses);
      return this.getCourses(keys, courses);
    },
    getCollegeList: function() {
      if (store.state.recommendations.colleges !== undefined) {
        this.courseFitData.recommended_colleges[this.level] = store.state.recommendations.colleges[this.level];
        return this.courseFitData.recommended_colleges[this.level];
      }
      return [];
    },

  },
  methods: {
    ...mapActions(['setSelectedCollege']),
    getCourses: function(keys, courses) {
      let self = this;
      if (courses !== {}) {
        if (!courses[self.filterCollegeName[this.level]] !== undefined) {
          keys.map(function(key) {
            if (key.toUpperCase() ===
                self.filterCollegeName[self.level].toUpperCase()) {
              self.filterCollegeName[self.level] = key;
            }
          });
        }
        if (courses[self.filterCollegeName[this.level]] === undefined) {
          self.filterCollegeName[this.level] = keys[0];
        }
        self.courseFitData.recommended_courses = courses[self.filterCollegeName[this.level]];
        return self.courseFitData.recommended_courses;
      }
      return {'overall': []};
    },
    filterByCollegeName: function(collegeName) {
      this.filterCollegeName[this.level] = collegeName;
      let temp = store.state.selectedCollege;
      temp[this.level] = collegeName;
      this.setSelectedCollege(temp);
      let element = document.getElementsByClassName('courseRow')[0];
      element.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
export default recommendationMixins;