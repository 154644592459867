<template>
    <div style="overflow-y: auto; height: 70vh;">
        <el-row>
            <el-col :span="12" class="yooni-fit-head-mobile head-mobile" style="color: white">
                Your Skillsvista Fit!
            </el-col>
        </el-row>
        <el-row>
            <el-col class="yooni-fit-head-mobile description-mobile " style="color: white">
                Complete the 3 steps below so our software can work its magic and find the perfect fitting courses for
                you – your Skillsvista Fit courses to be precise!
            </el-col>
        </el-row>
        <el-row :key="index" :style="{'padding': '10px'}" v-for="(obj, index) in yooniFitOptions">
            <el-col>
                <el-card :style="{ padding: '2vh 0' }" class="mobilecard">
                    <el-row type="flex">
                        <el-col :span="9" style="width: auto !important;">
                            <div class="step-class-mobile">
                                <svg fill="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="25" cy="25" fill="#898989" r="23"/>
                                    <text dominant-baseline="middle" fill="white" text-anchor="middle" x="50%" y="50%">
                                        {{index + 1}}
                                    </text>
                                </svg>
                            </div>
                            <img :src="`${publicPath}img/yooni_fit/${obj.image_url}.svg`" alt=""
                                class="mobileImage" style="background-color: white;">
                        </el-col>
                        <el-col :span="13" style="width: auto !important;">
                            <div class="card-title-mobile">{{obj.title}}</div>
                            <div class="card-desc-mobile">
                                {{obj.desc}}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row v-if="obj.type==='points_predict' && !getPredictionsSubmitted">
                        <el-button @click="$emit('slidePointsPredict')"
                                   class="mobile-signupButton findoutmore-button"
                                   type="button">
                            {{obj.button_text}}
                        </el-button>
                    </el-row>
                    <el-row v-else-if="obj.type==='points_predict' && getPointsPredicted">
                        <el-button @click="$emit('slidePointsPredict')"
                                   class="mobile-signupButton findoutmore-button" icon="el-icon-check"
                                   style="background: #2c3e50;"
                                   type="success">
                            Predicted CAO Points: <strong>{{$store.state.predictedCAOPoints}}</strong>
                        </el-button>
                    </el-row>
                    <el-row v-else-if="obj.type==='career_fit' && !getCareerFitCompleted">
                        <el-button @click="$emit('slideCareerTest')"
                                   class="mobile-signupButton findoutmore-button"
                                   type="button">
                            {{obj.button_text}}
                        </el-button>
                    </el-row>
                    <el-row v-else-if="obj.type==='career_fit' && getCareerFitCompleted">
                        <el-button :disabled="true" class="mobile-signupButton findoutmore-button" icon="el-icon-check"
                                   style="background: #EE7827; color: white !important;"
                                   type="success">
                            Careers Fit Completed
                        </el-button>
                    </el-row>
                    <el-row v-else-if="obj.type==='personality_fit' && !getInterestFitCompleted">
                        <el-button @click="$emit('slidePersonalityTest')"
                                   class="mobile-signupButton findoutmore-button"
                                   type="button">
                            {{obj.button_text}}
                        </el-button>
                    </el-row>
                    <el-row v-else-if="obj.type==='personality_fit' && getInterestFitCompleted">
                        <el-button :disabled="true" class="mobile-signupButton findoutmore-button" icon="el-icon-check"
                                   style="background: #EE7827; color: white !important;"
                                   type="success">
                            Personality Fit Completed
                        </el-button>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import yooniFitMixins from '../../mixins/yooni_fit_mixins';
  import {store} from '../../../store';

  export default {
    mixins: [yooniFitMixins],
    name: 'MobileYooniFitHome',
    mounted() {
      this.careerFitCompleted = store.state.careerFitCompleted;
      this.interestFitCompleted = store.state.interestFitCompleted;
    },
    computed: {},
    data() {
      return {};
    },
  };
</script>

<style>
    .el-tabs__content {
        /*padding: 0 1vw;*/
    }

    .el-button {
        /*padding: 1.2vh 1vw !important;*/
    }

    .mobileImage{
        width: 16vh;
        padding: 1vh;
        height: auto;
    }

    .mobileCard:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .button {
        padding: 0;
        margin: 0 auto;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }
</style>