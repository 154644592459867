<template>
    <div>
        <el-row style="display: flex; align-items: center;background-color: #17484C ;">
            <el-col :span="22" class="component-title" v-if="!isMobile()">
                Your Skillsvista Fit: <strong>{{$store.state.studentFullName}}</strong>
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-tabs id="yooniFitTabs" :stretch="true" :style="getStyle()"
                 @tab-click="showCourseFit=false"
                 v-model="$store.state.activeName">
            <el-tab-pane :style="!isMobile() ? {'padding-left': '16px'}:{padding: '16px 0'}" label="Course Fit"
                         name="course_fit"
                         v-if="!getTestCompletionStatus && !pointsPredict">
                <!--                <span slot="label" class="el-tabs__item">Course Fit  <img-->
                <!--                        :src="`${publicPath}img/tab_arrow.svg`" alt="''"/></span>-->
                <web-yooni-fit-home
                        @slidePointsPredict="slidePointsPredict"
                        @slideCareerTest="$store.state.activeName='career_fit'"
                        @slidePersonalityTest="$store.state.activeName='personality_fit'"
                        v-if="!isMobile() && !showCourseFit && !getLoadingScreenStatus"></web-yooni-fit-home>
                <mobile-yooni-fit-home
                        @slidePointsPredict="slidePointsPredict"
                        @slideCareerTest="$store.state.activeName='career_fit'"
                        @slidePersonalityTest="$store.state.activeName='personality_fit'"
                        v-else-if="isMobile() && !showCourseFit && !getLoadingScreenStatus"></mobile-yooni-fit-home>

                <personality-career-fit
                        :data="pointsPredictData" :testType="'points_predict'"
                        @showCareerTest="performCareerTest"
                        @showPersonalityTest="performPersonalityTest"
                        @showPointsPredict="showPointsPredict"
                        v-else-if="showCourseFit && !getLoadingScreenStatus">
                </personality-career-fit>
            </el-tab-pane>
            <el-tab-pane :style="!isMobile() ? {'padding-left': '16px'}:{padding: '16px 0'}" label="Course Fit"
                         name="course_fit" v-else>
                <!--                <span slot="label" class="el-tabs__item">Course Fit  <img-->
                <!--                        :src="`${publicPath}img/tab_arrow.svg`"/></span>-->
                <el-row v-if="pointsPredict"
                        :style="isMobile()?{height: '75vh'}:{height: 'calc(100vh - (3vh + 1vw) - 32px - 20px - 50px - 15px)'}"
                        style="overflow-y: auto;">
                    <select-subjects-new
                            @caoPointsSubmitted="pointsPredict=false, showCourseFit=false"
                            :routedFrom="'skillsvista_fit'"></select-subjects-new>
                    <!--                    <select-subjects-->
                    <!--                            :source="'points_predict'"-->
                    <!--                            @caoPointsSubmitted="pointsPredict=false, showCourseFit=false"-->
                    <!--                            :routedFrom="'skillsvista_fit'"></select-subjects>-->
                </el-row>
                <recommendation-timer
                        :timePassed="getTimePassed"
                        @onTimeup="showTimer = false"
                        v-else-if="!pointsPredict && !getLoadingScreenStatus && getShowTimer && getShowPointsPredictDisclaimer">
                </recommendation-timer>
                <el-row v-else-if="!pointsPredict && getShowPointsPredictDisclaimer && !getLoadingScreenStatus && !getShowTimer">
                    <recommendation-disclaimer
                            @startGeneratingRecommendations="startGeneratingRecommendations"></recommendation-disclaimer>
                </el-row>
                <el-row :style="isMobile() ? {padding: '10px'}: {}"
                        v-else-if="!pointsPredict && !getShowPointsPredictDisclaimer && !getShowTimer" id="courseLevel">
                    <div style="z-index: 99; float: right;"
                         :style="!isMobile() ? {right: 'calc(1vw + 10px)', position: 'absolute'}:{'padding-bottom': '5px'}">
                        <el-radio-group style="background: transparent;" v-model="$store.state.courseLevelActiveName"
                                        class="selectLevel">
                            <el-radio-button :title="'8'" size="mini"
                                             :label="'8'" border>
                                Level 8
                            </el-radio-button>
                            <el-radio-button :title="'6_7'" size="mini"
                                             :label="'6_7'" border>
                                Level 6/7
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                    <web-course-yooni-fit
                            :level="$store.state.courseLevelActiveName"
                            v-if="!isMobile()">
                    </web-course-yooni-fit>
                    <mobile-course-yooni-fit
                            :level="$store.state.courseLevelActiveName"
                            v-else-if="isMobile()">
                    </mobile-course-yooni-fit>
                </el-row>

            </el-tab-pane>
            <el-tab-pane :style="!isMobile() ? {}:{padding: '16px 0'}" label="Career Fit"
                         name="career_fit">
                <!--                <span slot="label" class="el-tabs__item">Career Fit  <img-->
                <!--                        :src="`${publicPath}img/tab_arrow.svg`"/></span>-->
                <recommendation-timer
                        :timePassed="getTimePassed"
                        @onTimeup="showTimer = false"
                        v-if="!getLoadingScreenStatus && getShowTimer && getShowPointsPredictDisclaimer"></recommendation-timer>
                <el-row v-else-if="getShowPointsPredictDisclaimer && !getLoadingScreenStatus && !getShowTimer">
                    <recommendation-disclaimer
                            @startGeneratingRecommendations="startGeneratingRecommendations"></recommendation-disclaimer>
                </el-row>
                <career-fit-test
                        @testCompleted="testCompletedFunc"
                        v-else-if="showCareerFitTest && !$store.state.careerFitCompleted"></career-fit-test>
                <personality-career-fit :data="careerData" :testType="'career_fit'"
                                        @showCareerTest="performTest('career_fit')"
                                        v-else-if="!$store.state.careerFitCompleted && $store.state.activeName==='career_fit' && !showCareerFitTest">
                </personality-career-fit>
                <career-fit-result
                        v-else-if="$store.state.careerFitCompleted && getTestCompletionStatus && !getShowPointsPredictDisclaimer && !getShowTimer"></career-fit-result>
                <personality-career-fit :data="testCompletedText" :testType="'career_fit_test_completed'"
                                        v-else></personality-career-fit>

            </el-tab-pane>
            <el-tab-pane :style="!isMobile() ? {}:{padding: '16px 0'}" label="Personality Fit"
                         name="personality_fit">
                <!--                <span slot="label" class="el-tabs__item">Personality Fit  <img-->
                <!--                        :src="`${publicPath}img/tab_arrow.svg`"/></span>-->
                <recommendation-timer
                        :timePassed="getTimePassed"
                        @onTimeup="showTimer = false"
                        v-if="!getLoadingScreenStatus && getShowTimer && getShowPointsPredictDisclaimer"></recommendation-timer>
                <el-row v-else-if="getShowPointsPredictDisclaimer && !getLoadingScreenStatus && !getShowTimer">
                    <recommendation-disclaimer
                            @startGeneratingRecommendations="startGeneratingRecommendations"></recommendation-disclaimer>
                </el-row>
                <personality-fit-test @testCompleted="testCompletedFunc"
                                      v-else-if="showPersonalityFitTest && !$store.state.interestFitCompleted">
                </personality-fit-test>
                <personality-career-fit
                        :data="interestData" :testType="'personality_fit'"
                        @showPersonalityTest="performTest('interest_fit')"
                        v-else-if="!$store.state.interestFitCompleted && $store.state.activeName==='personality_fit' && !showPersonalityFitTest">
                </personality-career-fit>
                <personality-fit-result
                        v-else-if="$store.state.interestFitCompleted && getTestCompletionStatus && !getShowPointsPredictDisclaimer && !getShowTimer">
                </personality-fit-result>
                <personality-career-fit :data="testCompletedText" :testType="'personality_fit_test_completed'"
                                        v-else></personality-career-fit>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
  import WebYooniFitHome from '../../page_components/YooniFitComponents/web/WebYooniFitHome';
  import MobileYooniFitHome from '../../page_components/YooniFitComponents/mobile/MobileYooniFitHome';
  import WebCourseYooniFit from '../../page_components/YooniFitComponents/web/WebCourseYooniFit';
  import MobileCourseYooniFit from '../../page_components/YooniFitComponents/mobile/MobileCourseYooniFit';
  import {store} from '../../store';
  import PersonalityFitResult from '../../page_components/YooniFitComponents/PersonalityFitResult';
  import CareerFitResult from '../../page_components/YooniFitComponents/CareerFitResult';
  import PersonalityCareerFit from '../../page_components/YooniFitComponents/web/WebPersonalityCareerFit';
  import PersonalityFitTest from '../../page_components/YooniFitComponents/PersonalityFitTest';
  import CareerFitTest from '../../page_components/YooniFitComponents/CareerTest';
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import RecommendationDisclaimer from '../../main_components/RecommendationDisclaimer';
  import RecommendationTimer from '../../main_components/RecommendationTimer';
  import SelectSubjectsNew from '../../main_components/SelectSubjectsNew';
  import SelectSubjects from '../../main_components/SelectSubjects';

  export default {
    name: 'YooniFit',
    components: {
      SelectSubjects,
      SelectSubjectsNew,
      RecommendationTimer,
      RecommendationDisclaimer,
      ProfileAvatar,
      CareerFitTest,
      PersonalityFitTest,
      PersonalityCareerFit,
      CareerFitResult,
      PersonalityFitResult,
      MobileCourseYooniFit, WebCourseYooniFit, MobileYooniFitHome, WebYooniFitHome,
    },
    beforeMount() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.checkUserLoggedIn().then(response => {
        if (response.user_type === 2) {
          self.$router.push('/counsellor/home/');
        }
        if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        }
        if (response.status === 0) {
          self.$router.push('/');
        }
        self.$store.state.loadingScreen = true;
        // self.$store.state.activeName = 'course_fit';
        self.showPointsPredictDisclaimer = false;
        self.showPersonalityFitTest = false;
        self.showCareerFitTest = false;
        self.$store.state.predictionsSubmitted = (self.$store.state.predictedCAOPoints !== false) &&
            (self.$store.state.predictedCAOPoints !== '');
        self.callSeriesOfTest();
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    mounted() {
      this.sendAnalytics();
    },
    computed: {
      getShowTimer: function() {
        if (this.$store.state.recommendations.recommendations_generated) {
          this.showTimer = false;
        }
        return this.showTimer;
      },
      getTimePassed: function() {
        return this.timePassed;
      },
      getPredictionsSubmitted: function() {
        return this.$store.state.predictionsSubmitted;
      },
      getShowPointsPredictDisclaimer: function() {
        let flag = this.pointsPredicted && this.interestFitCompleted && this.careerFitCompleted &&
            !this.$store.state.recommendations.recommendations_generated;
        if (flag && this.showTimer) {
          this.storeStartTime();
        }
        return flag;
      },
      getTestCompletionStatus: function() {
        this.predictedCAOPoints = store.state.predictedCAOPoints;
        this.interestFitCompleted = store.state.interestFitCompleted;
        this.careerFitCompleted = store.state.careerFitCompleted;

        return (this.pointsPredicted && this.interestFitCompleted && this.careerFitCompleted &&
            !this.$store.state.recommendations.recommendations_generated) ||
            this.$store.state.recommendations.recommendations_generated;
      },
    },
    watch: {
      pointsPredicted: function(val) {
        if (val && this.careerFitCompleted && this.interestFitCompleted) {
          this.fetchRecFlag = true;
        }
      },
      careerFitCompleted: function(val) {
        if (val && this.pointsPredicted && this.interestFitCompleted) {
          this.fetchRecFlag = true;
        }
      },
      interestFitCompleted: function(val) {
        if (val && this.pointsPredicted && this.careerFitCompleted) {
          this.fetchRecFlag = true;
        }
      },
      fetchRecFlag: function(val) {
        if (val) {
          this.fetchRecommendations();
        }
      },
    },
    data() {
      return {
        courseLevelActiveName: '8',
        fetchRecFlag: false,
        loadingMessage: '',
        termConditionsChecked: false,
        careerFitCompleted: false,
        interestFitCompleted: false,
        pointsPredicted: false,
        showPointsPredictDisclaimer: false,
        showPersonalityFitTest: false,
        showCareerFitTest: false,
        showCourseFit: false,
        loadingScreen: false,
        showTimer: true,
        allTestsPerformed: false,
        timePassed: false,
        pointsPredict: false,
        careerData: {
          'heading': 'Narrow down course areas that match your interests',
          'description': 'This Career Fit quiz matches you with your top 5 career sectors out of over 30, based on your personal interests and preferences. The quiz should take no more than 20 minutes. The test consists of 132 activities that you will have to rate by how much you would enjoy performing each on a scale from Dislike to Like.',
          'type': 'career_fit',
        },
        interestData: {
          'heading': 'Pin point courses most likely to bring you success and satisfaction',
          'description': 'This Personality Fit quiz uses the scientific Holland Code model to score you in 6 major personality areas. The quiz should take no more than 10 minutes. The test consists of 60 pairs of activities that you will have to rate by how much you would enjoy performing each.',
          'type': 'interest_fit',
        },
        pointsPredictData: {
          'heading': 'Find your most academically suitable courses',
          'description': 'Skillsvista’s Academic Fit algorithm analyses your Leaving Certificate subjects and predicted grades. Your results are compared them with 1000s of past successful college graduates in our database who are similar to you, in order to find courses you have the highest chance of excelling in academically. This is what separates Skillsvista’s recommendation accuracy from the rest!',
          'type': 'points_predict',
        },
        testCompletedText: {
          'heading': 'Complete all 3 steps so our software can work its magic and calculate your results.',
          'description': '',
          'type': 'test_completed',
        },
        courseFitData: {
          'recommended_courses': [],
          'recommended_colleges': [],
        },
      };
    },
    methods: {
      storeStartTime: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/timer/';
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          success: function(response) {
            if (response['time_passed'] === 0) {
              self.showTimer = false;
            } else {
              self.showTimer = true;
              self.timePassed = response['time_passed'];
            }
          },
          error: function(error) {
            console.log(error);
          },
        });
      },
      callSeriesOfTest: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        self.getPredictedCAOPoints().then(pointsPredictedData => {
          self.pointsPredicted = pointsPredictedData;
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          error;
          self.$store.state.loadingScreen = false;
          self.pointsPredicted = false;
        });
        // self.$store.state.loadingScreen = true;
        self.getCareerTestResults().then(careerFitCompleted => {
          self.careerFitCompleted = careerFitCompleted;
          // self.$store.state.loadingScreen = false;
        }).catch(errorData => {
          errorData;
          self.$store.state.loadingScreen = false;
          self.careerFitCompleted = false;
        });
        // self.$store.state.loadingScreen = true;
        self.getPersonalityTestResults().then(interestFitCompleted => {
          self.interestFitCompleted = interestFitCompleted;
          // self.$store.state.loadingScreen = false;
        }).catch(errorData => {
          errorData;
          self.$store.state.loadingScreen = false;
          self.interestFitCompleted = false;
        });
      },
      fetchRecommendations: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        self.getRecommendations().then(data2 => {
          data2;
          setTimeout(() => self.$store.state.loadingScreen = false, 2000);
          self.$store.state.loadingScreen = false;
        }).catch(errorData => {
          self.$store.state.loadingScreen = false;
          console.log('Error generating recommendations', errorData);
        });
      },
      startGeneratingRecommendations: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        self.$store.state.loadingMessage = 'Generating Results...';
        self.generateRecommendations().then(data => {
              self.$analytics.fbq.event('Purchase', {
                name: self.$store.state.studentFullName,
                email: self.$store.state.studentEmail,
                amountPaid: 0,
                currency: 'euro',
              });
              self.getRecommendations().then(data2 => {
                data2;
                self.showPointsPredictDisclaimer = false;
                self.$store.state.loadingScreen = false;
                self.$store.state.loadingMessage = '';
                self.$store.state.recommendationsGenerated = true;
                setTimeout(() => self.$store.state.showFeedbackDialogue = true, 60000);
              }).catch(errorData => {
                self.$store.state.loadingScreen = false;
                self.$store.state.loadingMessage = '';
                errorData;
                // console.log('Error generating recommendations', errorData);
              });
            },
        ).catch(errorData => {
          self.$store.state.loadingScreen = false;
          errorData;
          // console.log('Error generating recommendations', errorData);
        });
      },
      testCompletedFunc: function() {
        this.callSeriesOfTest();
        this.$store.state.activeName = 'course_fit';
      },
      performTest: function(value) {
        if (value === 'career_fit') {
          this.performCareerTest();
        } else {
          this.performPersonalityTest();
        }
      },
      performCareerTest: function() {
        // this.$router.push({name: 'CareerTest'});
        this.$store.state.activeName = 'career_fit';
        this.showCareerFitTest = true;
      },
      slidePointsPredict: function() {
        this.showCourseFit = true;
      },
      showPointsPredict: function() {
        this.pointsPredict = true;
      },
      performPersonalityTest: function() {
        // this.$router.push({name: 'PersonalityTest'});
        this.$store.state.activeName = 'personality_fit';
        this.showPersonalityFitTest = true;
      },
      getStyle: function() {
        let styleObject = {};
        styleObject['width'] = '100%';
        if (this.isMobile()) {
          styleObject['margin-top'] = '0';
        } else {
          // styleObject['margin-top'] = '-0.5%';
        }
        return styleObject;
      },
    },
  };
</script>
<style scoped>
    /*.selectLevel >>> .el-radio-button__inner {*/
    /*    opacity: 0.7;*/
    /*}*/

    /*.selectLevel >>> .el-radio-button__inner:hover {*/
    /*    !*background: #409EFF !important;*!*/
    /*    !*color: #ffffff !important;*!*/
    /*    opacity: 1;*/
    /*}*/

    /*.selectLevel >>> .el-radio-button__orig-radio:checked + .el-radio-button__inner {*/
    /*    !*color: black;*!*/
    /*}*/

</style>
<style>

    .el-tabs__content {
        padding: 0;
    }

    .el-tabs__header {
        background: #ffffff;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        /* identical to box height, or 267% */
        color: #000000;
    }

    .el-tabs__item.is-active {
        font-weight: 900 !important;
    }


    .el-tabs__active-bar {
        /*width: 25% !important;*/
        margin: 0 auto;
        background-color: #409EFF !important;
        top: 0;
    }

    .el-tabs__item.is-disabled {
        color: #C0C4CC !important;
        cursor: not-allowed !important;
    }
</style>
